import '../styles/main.scss'

if (module.hot) module.hot.accept()

document.addEventListener('DOMContentLoaded', function DOMContentLoaded() {
  class MenuHover {
    constructor() {
      this.menu = document.getElementById('menu-hauptmenue')
      this.menuItems = [...this.menu.getElementsByClassName('menu-item')]
      this.hoverspan = document.getElementById('hoverSpan')
      /* eslint-disable */
      this.activeMenuItem = document.getElementsByClassName('current_page_item')[0]
      /* eslint-enable */
      this.targetLeft = 0
      this.targetWidth = 0

      this.addEventListeners()
    }

    addEventListeners = () => {
      this.menuItems.forEach((menuItem) => {
        menuItem.addEventListener('mouseenter', this.onMouseEnterMenuItem)
        menuItem.addEventListener('mousedown', this.onMouseDownMenuItem)
        menuItem.addEventListener('touchstart', this.onMouseDownMenuItem)
        menuItem.addEventListener('mouseup', this.onMouseUpMenuItem)
        menuItem.addEventListener('touchstop', this.onMouseUpMenuItem)
      })
      this.menu.addEventListener('mouseenter', this.onMouseEnterMenu)
      this.menu.addEventListener('mouseleave', this.onMouseLeaveMenu)
    }

    onMouseDownMenuItem = () => {
      this.hoverspan.style.borderBottomWidth = '2px'
    }

    onMouseUpMenuItem = () => {
      this.hoverspan.style.borderBottomWidth = '1px'
    }

    onMouseEnterMenuItem = (e) => {
      const current = e.currentTarget
      this.targetLeft = current.offsetLeft
      this.targetWidth = current.offsetWidth
      this.setPostion()
    }

    onMouseLeaveMenuItem = () => {
      this.hoverspan.style.opacity = 0
    }

    onMouseEnterMenu = () => {
      this.hoverspan.style.opacity = 1
    }

    onMouseLeaveMenu = () => {
      this.hoverspan.style.opacity = 0
    }

    setPostion = () => {
      this.hoverspan.style.left = `${this.targetLeft}px`
      this.hoverspan.style.width = `${this.targetWidth}px`
    }
  }

  /* eslint-disable */
  new MenuHover()
  /* eslint-enable */
})
